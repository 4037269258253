.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
}

.nav {
  max-width: 1400px;
  width: 100%;
  background: #0c1715;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 30px 50px;
  border: 1px solid #213f38;
  color: #9b9b9b;

  &_logo {
    display: flex;
    align-items: center;
    gap: 5px;

    &_img {
      height: 33px;
    }

    &_text {
      font-size: 25px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.03em;
    }
  }

  &_links {
    display: flex;
    gap: 13px;
  }

  &_link {
    text-decoration: none;
    color: #9b9b9b;
    font-family: sans-serif !important;
  }

  &_contactLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
  }
}
