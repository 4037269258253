.home {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url(../../images/Figures.png);
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff73;

  &_wrapper {
    width: 100%;
    max-width: 1400px;
    display: flex;
    position: relative;
  }

  &_left {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    &_serverInfo {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 17px;
      font-weight: 400;
      line-height: 21.25px;
      font-family: "Lexend";
      color: #ffffff73;
      padding: 10px;

      b {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #78e09b;
      }
    }

    &_title {
      color: transparent;
      background: linear-gradient(
        45deg,
        #4d62e5 0%,
        #87ddee 45.31%,
        #b6f09c 100%
      );
      text-align: left;
      width: 100%;

      -webkit-background-clip: text;

      font-size: 60px;
      font-weight: 700;
      line-height: 44px;
    }

    &_desc {
      font-size: 24px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.03em;
    }

    &_map {
      margin-top: 60px;
      margin-bottom: 40px;
      padding: 10px 70px;
      width: fit-content;
      display: flex;
      gap: 8px;
      color: #e8e9e9;
      align-items: center;
      text-decoration: none;
      background: linear-gradient(
        117.58deg,
        rgba(34, 255, 255, 0.16) -47.79%,
        rgba(42, 55, 55, 0) 100%
      );
      border-radius: 8px;
    }

    &_links {
      width: 100%;
      display: flex;
      gap: 65px;

      a {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        background: linear-gradient(
          117.58deg,
          rgba(0, 87, 255, 0.16) -47.79%,
          rgba(204, 235, 235, 0) 100%
        );
        text-decoration: none;
        color: #fff;
        border-radius: 8px;
        width: 252px;
        border-top: 1px solid #ffffff25;

        img {
          height: 45px;
          border-radius: 50%;
          opacity: 0.5;
        }

        p {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }

  &_right {
    width: calc(100% - 700px);
    position: absolute;
    right: 0;

    img {
      width: 100%;
    }
  }
}
